
/*function openlink(event){
  
  event.preventDefault();
  event.stopPropagation();

  try{
    var requestinfo = [];
    if(window.angularComponentReference){
       window.angularComponentReference.zone.run(()=>{
        requestinfo = window.angularComponentReference.componentFn(event)});
    }
    //adding loader
    if(requestinfo && requestinfo['basepath']){
      event.srcElement.insertAdjacentElement('afterend',getLoaderElm());
      getFile(event,requestinfo);
    }
  }catch(e){
    reset();
  }
  return false;
}

function reset(){
  removeEle('cmsloader');
  removeEle('videomodal');
}

function getname(name){
  if (name) {
      return name.substr((name.lastIndexOf("/")+1), name.length);
  }
  return name;
}

function getlocation(event){
	
	if (event.srcElement === undefined){
        //scrElement is undefined in Firefox and textContent is depreciated
        console.log('IF source is not defined',event.originalTarget.attributes.href.value);
        return event.originalTarget.attributes.href.value;
    } else {
        console.log('IF source is not defined',event.srcElement.attributes.href.textContent);
        return event.srcElement.attributes.href.textContent;
    }
}

function getModal(){

  var modal = document.createElement('div');
  modal.classList.add('modal','fade');
  modal.setAttribute('id', 'videomodal');
  modal.setAttribute('tabindex', '-1');
  modal.setAttribute('role', 'dialog');
  modal.setAttribute('aria-labelledby', 'videomodal');
  modal.setAttribute('aria-hidden', 'true');
  modal.innerHTML =
        '<div class="modal-dialog" role="document">'+ 
        '<div class="modal-header">'+
          '<button type="button" class="close" data-dismiss="modal" data-target="#videomodal" aria-label="Close"></button>'+
        '</div>' +
        '<div class="modal-content" id="modal-content">'
        '</div>' +
        '</div>';
  return modal;
}

function getLoaderElm(){
  let div = document.createElement('div');
  div.setAttribute('class','loader');
  div.setAttribute('id','cmsloader');
  return div;
}

function openVideo(data,type){

    var modalElm = document.getElementById('videomodal');
    if(modalElm){
      window.document.body.removeChild(modalElm);
    }

    var video = document.createElement('video');
    video.id = 'video'
    video.autoplay = true;

    var sourceMP4 = document.createElement("source"); 
    sourceMP4.type = type;
    sourceMP4.src = data;
    video.appendChild(sourceMP4);

    video.setAttribute("width", "400");
    video.setAttribute("height", "400");
    video.setAttribute("controls", "controls");
    document.getElementById('modal-content').removeChild();
    document.getElementById('modal-content').appendChild(video);
    
}

function openfile(blob,name,type){

  if(blob==null) return;

  const blobUrl = URL.createObjectURL(blob);

  //if(type.indexOf('video')!=-1){
      openVideo(blobUrl, type);
  //}
  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;
  link.target = "_blank";

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );
  // Remove link from body
  document.body.removeChild(link);
}

function removeEle(id){
  var element = document.getElementById(id);
  if(element)
    element.remove();
}

function addModal(){
    window.document.body.appendChild(getModal());
    $('#videomodal').modal('show');
    document.getElementById('modal-content').appendChild(getLoaderElm());
}

function getFile(event,requestinfo){

  var xhttp = new XMLHttpRequest();
  var location =  getlocation(event);
  var filename = getname(location);

  
  xhttp.onreadystatechange = function() {

  if (this.readyState == 4 && this.status == 200) {
      var type = this.response ? this.response.type:null;
      removeEle('cmsloader');
      var blob = new Blob([this.response]);
      
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      } 
      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      //const data = window.URL.createObjectURL(blob);
      openfile(blob,filename,type);
    }
  };

  //if(filename.indexOf('.mp4')){
    addModal();
  //}

  var application;

  if(requestinfo){
    location = requestinfo['basepath'] + location;
    application = requestinfo['x-application'];
  } else{
    location = '/protected' + location;
    application = 'qs3';
  }

  xhttp.onreadystatechange = function() {

    if (this.readyState == 4 && this.status == 200) {
      var blob = this.response;
      console.log(blob.size);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      } 
    
      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);
      window.open(data, '_blank');
      setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    }
  };
  
  

  xhttp.open("GET", location, true);
  xhttp.setRequestHeader('x-application',application);
  xhttp.setRequestHeader('X-XSRF-TOKEN',getCookie('XSRF-TOKEN'));
  xhttp.responseType = "blob";
  xhttp.withCredentials = true;
  xhttp.send();
}

function getCookie(name){
    var pattern = RegExp(name + "=.[^;]*")
    var matched = document.cookie.match(pattern)
    if(matched){
        var cookie = matched[0].split('=')
        return cookie[1]
    }
    return false;
}*/



function openlink(event){
	
	try{
		
		var event = event || window.event;
		
		//stopping default anchor tag event event
		
		if(event.stopPropagation){
			event.stopPropagation();
		}else if (event.preventDefault) {
			event.preventDefault();
		} else {
			event.returnValue = false;
		}
		
		var location;
		var requestinfo = [];
		//event.preventDefault();
		
		if(window.angularComponentReference){
		  window.angularComponentReference.zone.run( function(){
			requestinfo = window.angularComponentReference.componentFn(event)});
		}
		if (event.currentTarget === undefined){
			//To support IE 8 and below
			//console.log('IF target is not defined',event.srcElement);
			location = event.srcElement.pathname;
			
			if(!location){
				location = event.srcElement.parentElement.pathname;
			}
			
			if(location && location.indexOf("/files") < 0){
				location = "/"+ location;
			}
		} else {
			//console.log('IF target is defined',event.target.attributes.href.textContent);
			location = event.currentTarget.attributes.href.textContent;
		}
		getFile(location,requestinfo);
	}catch(err){
		console.log("CMS file download Error",err);
	}	
	return false;
}

function openfile(blob,name){
	
  /*if(blob==null) return;
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );

  // Remove link from body
  document.body.removeChild(link);*/
}

function removelink(id){
  var link = document.getElementById(id);
  if(link){
	document.body.removeChild(link);
  }
}

function openNewTab(url){
	
	window.open(url,"_blank");
}

function openFileInTab(url,filename){

    var agent = window.navigator.userAgent;
	
    if(agent.indexOf("Chrome") > -1 || agent.indexOf("Firefox") > -1){
		openNewTab(url);
	}else{
	  
	  //removelink("cmsfile");   
	  // Create a link element
	  var link = document.createElement("a");
	  // Append link to the body
	  document.body.appendChild(link);
	  // Set link's href to point to the Blob URL
	  //link.style = "display: none";
	  link.href = url;
	  link.id ="cmsfile";
	  link.download = filename;
	  link.target = "_blank";
	  //link.download = filename;//to show the filename
	  link.click();
	  // Dispatch click event on the link
	  // This is necessary as link.click() does not work on the latest firefox
	  /*link.dispatchEvent(
		new MouseEvent('click', { 
		  bubbles: true, 
		  cancelable: true, 
		  view: window 
		})
	  );*/
	}
    
  // Remove link from body
	 document.body.removeChild(link);
}

function isVideo(location){
	var pattern = /^([a-zA-Z0-9\/\s_-])+(.mp4|.flv|.swf|.ogv|.webm)*$/
	return pattern.test(location);
}
function getFile(location,requestinfo){
	
	var basepath = '/protected';
	var isVideo = this.isVideo(location);
	var filename = getname(location);
	
	if(isVideo){
		basepath = "video_common";
		location = "https://quickserve.cummins.com/" + basepath + location;
	}else{
		
		if(requestinfo && requestinfo['basepath']){
			location = requestinfo['basepath'] + location;
		}else{
			location = basepath + location;
		}
	}
	
	openFileInTab(location,filename);
}
	
function getFileAjax(location,requestinfo){

  var xhttp = new XMLHttpRequest();
  var application;

  var filename = getname(location);
  
  xhttp.onreadystatechange = function() {
	
    if (this.readyState == 4 && this.status == 200) {
      var blob = blob = new Blob([this.response]);
      //console.log(blob.size);
	  //text = "hello world",
	  //blob = new Blob([text], { type: 'text/plain' }),

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      } 
    
      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
       //const data = window.URL.createObjectURL(blob);
	  openfile(blob,filename);
	   
      //window.open(data, '_blank');
      /*setTimeout(function(){
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);*/
    }
  };
  
  if(requestinfo){
    location = requestinfo['basepath'] + location;
    application = requestinfo['x-application'];
	xhttp.setRequestHeader('X-XSRF-TOKEN',getCookie('XSRF-TOKEN'));
  } else{
    location = '/protected' + location;
    application = 'qs3';
	
  }

  xhttp.open("GET", location, true);
  xhttp.setRequestHeader('x-application',application);
  xhttp.responseType = "blob";
  xhttp.withCredentials = true;
  xhttp.send();
}

function getname(name){
	if (name) {
      return name.substr((name.lastIndexOf("/")+1), name.length);
    }
    return name;
}
function getCookie(name){
    var pattern = RegExp(name + "=.[^;]*")
    var matched = document.cookie.match(pattern)
    if(matched){
        var cookie = matched[0].split('=')
        return cookie[1]
    }
    return false;
}