function loadCumminsTemplates() {
    CKEDITOR.config.forcePasteAsPlainText = true;
    CKEDITOR.config.templates_files = [CKEDITOR.getUrl('/app/cms-editor/common/cummins-templates.js')];
    CKEDITOR.config.templates_replaceContent = true;
    CKEDITOR.plugins.addExternal('youtube', '/assets/plugins/youtube/', 'plugin.js');
    CKEDITOR.plugins.addExternal('btgrid', '/assets/plugins/btgrid/', 'plugin.js');
    CKEDITOR.plugins.addExternal('bt_table', '/assets/plugins/bt_table/', 'plugin.js');
    CKEDITOR.plugins.addExternal('collapsibleItem', '/assets/plugins/collapsibleItem/', 'plugin.js');
    CKEDITOR.plugins.addExternal('bootstrapVisibility', '/assets/plugins/bootstrapVisibility/', 'plugin.js');
    CKEDITOR.plugins.addExternal('c_tree', '/assets/plugins/c_tree/', 'plugin.js');
    CKEDITOR.addTemplates("default", {
        imagesPath: CKEDITOR.getUrl('/assets/'),
        templates: [
            {
                title: 'IAC: Template-1',
                image: 'icons/apple-touch-icon-57x57.png',
                description: 'QSOL Dealer and Distributor',
                html: '<div id="public" style="border:1px solid black; width:100%">' +
                    '<div id="publicCont"><p>Public Content</p></div>' +
                    '</div>' +

                    '<div id="QSOL Dealer" class="template" style="border:1px solid black; float:left; width:100%">' +
                    '<div id="dealerCont"><p>Dealer Contents</p></div>' +
                    '</div>' +

                    '<div id="QSOL Distributor" class="template" style="border:1px solid black; float:left; width:100%">' +
                    '<div id="distCont"><p>Distributor Content</p></div>' +
                    '</div>' +

                    '<div id="Employee" class="template" style="border:1px solid black; float:left; width:100%">' +
                    '<div id="employeeCont"><p>Employee Content</p></div>' +
                    '</div>'
            }
        ]
    });
}
